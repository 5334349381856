import type { ComponentProps } from 'react'
import React from 'react'
import styled from 'styled-components'

import _TextImage from '../TextImage'

const TextImage = styled(_TextImage)`
  margin-top: 24px;

  img {
    border-radius: 24px;
    max-height: 480px;
    float: right;
  }
`

const Hero = (props: ComponentProps<typeof TextImage>) => (
  <TextImage reversed useH1 {...props} />
)

export default Hero
