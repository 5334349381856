import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import type { FooterSignUpLinkQuery } from '../../../graphql-types'
import Container from '../../components/modules/Container'
import Spacer from '../../components/modules/Spacer'
import Text, { TextType } from '../../components/modules/Text'
import { NEW_SIGNUP_PATH } from '../../constants'

export const footerLinkQuery = graphql`
  query FooterSignUpLink {
    link: contentfulLink(
      contentful_id: { eq: "HxkQtzA3rOoKO2MEho5h1" }
      node_locale: { eq: "en" }
    ) {
      ...Link
    }
  }
`

const Footer = () => (
  <StaticQuery
    query={footerLinkQuery}
    render={(data: FooterSignUpLinkQuery) => {
      const link = data.link || {
        __typename: 'ContentfulLink',
        url: NEW_SIGNUP_PATH,
        label: 'Get Started',
        button: true,
      }
      return (
        <Container>
          <Spacer size={'Medium'} />

          <Text
            __typename={'ContentfulText'}
            type={TextType.CTA}
            title={'Ready to save time?'}
            text={{ text: 'Start working with a Double assistant today.' }}
            link={link}
          />

          <Spacer size={'Large'} />
        </Container>
      )
    }}
  />
)

export default Footer
