import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import type {
  CaseStudyQuery,
  CaseStudyQueryVariables,
  IconItemFragment,
} from '../../../graphql-types'
import Page from '../../components/layout/Page'
import Module, { Container } from '../../components/modules'
import Img from '../../components/modules/Img'
import Footer from '../../components/resource/Footer'
import Hero from '../../components/resource/Hero'
import { Color } from '../../components/theme'
import { bodyRegularStyle } from '../../components/typography'
import { linkify } from '../../components/utils'

export type Query = CaseStudyQuery
export type QueryVariables = CaseStudyQueryVariables
export type Context = QueryVariables

export const query = graphql`
  query CaseStudy($slug: String!) {
    page: contentfulCaseStudy(slug: { eq: $slug }, node_locale: { eq: "en" }) {
      ...FullCaseStudy
    }
  }
`

const Bullets = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const BulletOuter = styled.div`
  ${bodyRegularStyle}
  display: flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
`

const BulletIcon = styled(Img)`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  margin-top: -1px;
`

const Bullet = ({ icon, title }: IconItemFragment) => {
  const href = title && linkify.match(title)?.[0].url

  return (
    <BulletOuter>
      <BulletIcon asset={icon} />
      {href ? (
        <a href={href} target={'_blank'}>
          {title}
        </a>
      ) : (
        title
      )}
    </BulletOuter>
  )
}

const CaseStudy = ({ data: { page } }: PageProps<Query, Context>) => (
  <Page
    head={{
      title: `Customers - ${page?.title}`,
      description: page?.description?.description || page?.title || '',
      image: page?.poster?.file?.url,
      squareImage: page?.poster?.file?.url,
      openGraphType: 'article',
    }}
  >
    <Container>
      <Hero
        title={page?.title}
        text={page?.heroText?.heroText}
        image={page?.poster}
        category={{
          name: 'Customer Spotlight',
          color: Color.PURPLE_6,
        }}
      >
        {page?.heroBullets?.length > 0 && (
          <Bullets>
            {page?.heroBullets?.map(
              (bullet, index) => !!bullet && <Bullet key={index} {...bullet} />,
            )}
          </Bullets>
        )}
      </Hero>
    </Container>

    {page?.modules?.map(
      (module, index) =>
        !!module && (
          <Module key={`${module.__typename || ''}${index}`} module={module} />
        ),
    )}

    <Footer />
  </Page>
)

export default CaseStudy
